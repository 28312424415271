<template>
    <defaultSec :title="'< 返回'" :returnState="true">
        <div>
            <el-form :model="form" label-width="120px">
                <el-form-item label="梯队id" v-show="false" prop="echelonId" :required="true">
                    <el-input v-model="form.echelonId" placeholder="梯队id"></el-input>
                </el-form-item>
                <el-form-item label="梯队名称" prop="echelonName" :required="true">
                    <el-input v-model="form.echelonName" placeholder="梯队名称"></el-input>
                </el-form-item>
                <el-form-item label="期次" prop="periodId" :required="true">
                    <el-select v-model="form.periodId" filterable clearable placeholder="期次">
                        <el-option v-for="item in periodList"
                                   :key="item.periodId"
                                   :label="item.periodDesc"
                                   :value="item.periodId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="绩效系数" prop="performanceCoefficient" >
                    <el-input v-model="form.performanceCoefficient" :disabled="form.eventId>0" placeholder="请输入绩效系数"></el-input>
                </el-form-item>
                <el-form-item label="实际GMV" prop="gmv"  :required="true">
                    <el-input v-model="form.gmv" :disabled="form.eventId>0" placeholder="实际GMV"></el-input>
                </el-form-item>
                <el-form-item label="结算时间" prop="importGmvTime" >
                    <el-date-picker v-model="form.importGmvTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="目标GMV" prop="targetGmv" >
                    <el-input v-model="form.targetGmv"  placeholder="目标GMV"></el-input>
                </el-form-item>
                <el-form-item label="父级梯队" prop="parentEchelonId" >
                    <el-select v-model="form.parentEchelonId" filterable clearable placeholder="父级梯队">
                        <el-option v-for="item in echelonList"
                                   :key="item.echelonId"
                                   :label="item.echelonName"
                                   :value="item.echelonId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" :required="true">
                    <el-input-number v-model="form.sort" :min="1" :max="1000" placeholder=""></el-input-number>
                </el-form-item>
                <el-form-item label="结算状态" prop="status" >
                    <el-select v-model="form.status" filterable clearable placeholder="状态">
                        <el-option v-for="item in statusList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="false">
                    <el-button-group>
                        <el-button type="primary" @click="save" size="mini" class="header-btn">
                            保存
                        </el-button>
                    </el-button-group>
                </el-form-item>
            </el-form>

        </div>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import ElDialog from "../../../../node_modules/element-ui/packages/dialog/src/component";
    export default {
        data() {
            return {
                showDialog: false,
                form: {
                    echelonId: this.$route.params.echelonId || 0,
                    echelonName: '',
                    periodId:0,
                    periodName:'',
                    periodType:1,
                    performanceCoefficient:'',
                    gmv:'',
                    importGmvTime:'',
                    createTime:'',
                    updateTime:'',
                    targetGmv:'',
                    parentEchelonId:'',
                    sort:0,
                    status:''
                },
                statusList: [{id: 0, name: '未结算'}, {id: 1, name: '已结算'}],
                periodList:[],
                echelonList:[]
            }
        },
        components: {
            ElDialog, defaultSec, tablePagination
        },
        mounted(){
            this.initPeriodListList()
            this.initEchelonList()
            if (this.form.echelonId > 0) {
                this.getDetail()
            }
        },
        methods: {
            initPeriodListList(){
                this.$apiGet('lechun-cms/echelon/getPeriodList', this.formParam).then(res => {
                    console.log(res)
                    this.periodList = res.list
                })
            },
            initEchelonList(){
                this.$apiGet('lechun-cms/echelon/getDataList', {}).then(res => {
                    console.log(res)
                    this.echelonList = res.list
                })
            },
            getDetail() {
                this.$apiGet('lechun-cms/echelon/getEchelon', {echelonId: this.form.echelonId}).then(res => {
                    this.form = res
                })
            },
            save(){
                if (this.form.eventGroupId == '') {
                    this.$message({
                        showClose: true,
                        message: '事件分组必选',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventTableName == '') {
                    this.$message({
                        showClose: true,
                        message: '表名称名称必填',
                        type: 'error'
                    });
                    return;
                }
                if ((this.form.eventTableName.toUpperCase().indexOf("JOIN")==-1&&this.form.eventTableName.toUpperCase().indexOf("WHERE")==-1)&&this.form.eventDataBase == '') {
                    this.$message({
                        showClose: true,
                        message: '数据库名称必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventName == '') {
                    this.$message({
                        showClose: true,
                        message: '事件名称必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventType == 1 && this.form.readTemplate == '') {
                    this.$message({
                        showClose: true,
                        message: '读模板必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventType == 3 && this.form.writeTemplate == '') {
                    this.$message({
                        showClose: true,
                        message: '写模板必填',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.eventId == 0) {
                    this.form.eventId = ''
                }
                this.$apiGet('lechun-cms/event/saveEvent', this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$router.push({path: '/event', name: 'event'})
                })
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>